export interface GetContactResposne {
  first_name: string;
  last_name: string;
  phone: string;
  id: string;
  email_id: string;
  city_name: string;
  enquiry: string;
  describe_in_detail: string;
  docare_news_updates: number;
  privacy_policy: number;
  created_at: string;
}

export interface GetEmailResposne {
  id: string;
  email_id: string;
  is_active: string;
  created_at: string;
  updated_at: string;
}

export interface GetTestimonialResponse {
  id: any;
  name: string;
  role: string;
  description: string;
  is_active: number;
  created_at: string;
  profile_pic: any;
  location: string;
  updated_at: string;
}

export interface AddNewTestimonialProps {
  name: string;
  role: string;
  description: string;
  profile_pic: any;
  location: string;
}
export interface AddTeamMemberProps {
  name: string;
  role: string;
  speciality: string;
  description: string;
  profile_pic: any;
}
export interface GetTeamMemberResponse {
  id: number;
  name: string;
  role: string;
  speciality: string;
  description: string;
  profile_pic: string;
  is_active: number;
  created_at: string;
  updated_at: string;
}
export interface GetPricePlaneResponse {
  id: any;
  plan_type: string;
  plan_price: string;
  name: string;
  visits: string;
  time_period: string;
  scheduling: string;
  is_active: number;
  created_at: string;
  updated_at: string;
}

export interface AddPricePlaneProps {
  plan_type: string;
  plan_price: string;
  name: string;
  visits: string;
  time_period: string;
  scheduling: string;
  comment4: string
}

export interface GetWorkWith {
  address: string;
  agree_to_credit_and_background_check: string;
  allergic_to_animals: string;
  allergy_details: string;
  area_name: string;
  available_to_work: string;
  can_start_working: string;
  caregiving_duties: string;
  children_work_exp_details: any;
  city: string;
  contact_number: string;
  created_at: string;
  dob: string;
  duration_of_time_children: string;
  duration_of_time_elderly: string;
  duration_of_time_other: string;
  education_from: string;
  education_level: string;
  education_to: string;
  elderly_people_work_exp_details: any;
  email: string;
  experience_with_children: string;
  experience_with_elderly: string;
  first_name: string;
  healthcare_name: string;
  hours_per_week: string;
  i_have_a_swedish_bank_account: string;
  id: string;
  institution_name: string;
  name_of_the_institution: string
  interests_and_hobbies: string;
  is_active: string;
  key_responsibilities_children: string;
  key_responsibilities_elderly: string;
  key_responsibilities_other: string;
  lang_english_rate: string;
  lang_swedish_rate: string;
  last_name: string;
  my_preferred_work_location: string;
  other_language_details: any;
  other_language_name: string;
  other_language_rate: string;
  other_work_exp_details: any;
  other_work_experience: string;
  police_clearance_certificate: string;
  police_clearance_certificate_details: string;
  postal_code: string;
  prefer_to_work_with: any;
  preferred_work_with: string;
  privacy_policy_agreement: string;
  right_to_work_sweden: string;
  studies_or_work_experience_in_healthcare: string;
  tell_us_what_you_are_currently_doing: string;
  title_of_role_children: string;
  title_of_role_elderly: string;
  title_of_role_other: string;
  updated_at: string;
  valid_driver_licence: string;
  why_work_for_DoCare: string;
  work_citizin_type: string;
  work_for_at_least_6_months: string;
  work_permit_card: string;
  working_date: string;
  passport: any;
  how_do_you_hear_about_us: string;

  proof_of_your_studies: string;

  type_of_study: string;

  per_state_hours_week: string;

  i_am_self_employed_freelancer: string;
  i_am_retired_pensioner?: string;
  i_have_another_primary_occupation?: string;
}

export interface InitiaState {
  isLoading: boolean;
  isLoggedIn: boolean
  getContactusLists: Array<GetContactResposne>;
  addContactusResposne: any;
  setSelectedMessage: GetContactResposne | null;
  getEmailsList: Array<GetEmailResposne>;
  getTestimonialListResposne: Array<GetTestimonialResponse>;
  addNewTestimonial: AddNewTestimonialProps;
  getPricePlaneList: Array<GetPricePlaneResponse>;
  addPricePlaneProsp: AddPricePlaneProps;
  addteammember: AddTeamMemberProps;
  addteamlist: Array<GetTeamMemberResponse>;
  getWorkWithusResposne: Array<GetWorkWith>;
  setSelectedApplicationForm: GetWorkWith | null;
}

export const initialState: InitiaState = {
  isLoading: false,
  getContactusLists: [],
  addContactusResposne: null,
  addteamlist: [],
  setSelectedMessage: null,
  getEmailsList: [],
  getTestimonialListResposne: [],
  addNewTestimonial: {
    name: "",
    role: "",
    profile_pic: "",
    location: "",

    description: "",
  },
  getPricePlaneList: [],
  addPricePlaneProsp: {
    plan_price: "",
    plan_type: "",
    name: "",
    visits: "",
    time_period: "",
    scheduling: "",
    comment4: ''
  },
  addteammember: {
    name: "",
    role: "",
    speciality: "",
    description: "",
    profile_pic: " ",
  },
  getWorkWithusResposne: [],
  setSelectedApplicationForm: null,
  isLoggedIn: false
};
