import { ChangeEvent, ReactElement, useState } from "react";
import {
  InputValues,
  Label,
  LoginButton,
  LoginContainer,
  LoginWrapper,
  Title,
} from "./subcomponents";
import { InstituteIntialValues, intituteLoginInitialValues } from "./const";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AppDispatch, RootState } from "../state/store";
import { adminLogin } from "../state/controllers/api";
import { Loader } from "../component";
import ROUTES from "../const/routes";

const Login = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [instituteValues, setInstituteValues] = useState(
    intituteLoginInitialValues as InstituteIntialValues
  );
  const {
    loginResponseState: { isLoading },
  } = useSelector(
    (state: RootState) => ({
      loginResponseState: state.inbox,
    }),
    shallowEqual
  );

  const canSubmit = !!instituteValues?.email_id && !!instituteValues?.password;

  const handleSubmit = () => {
    if (!canSubmit) {
      alert("Please fill out the username & password to login.");
    } else {
      dispatch(
        adminLogin({
          ...instituteValues,
        })
      ).then((res) => {
        if (res?.meta?.requestStatus === "fulfilled") {
          navigate(ROUTES.MESSAGES);
        } else if (res?.meta?.requestStatus === "rejected") {
          alert("Server Error");
        }
      });
    }
  };

  return (
    <LoginWrapper>
      <LoginContainer>
        <Title>Sign In.</Title>
        <div className="mt-4">
          <Label>Username</Label>
          <InputValues
            placeholder="username"
            value={instituteValues?.email_id}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setInstituteValues({
                ...instituteValues,
                email_id: e.target.value,
              });
            }}
          />
          <Label className="mt-4">Password</Label>
          <InputValues
            placeholder="password"
            type={"password"}
            value={instituteValues?.password}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setInstituteValues({
                ...instituteValues,
                password: e.target.value,
              });
            }}
          />
        </div>
        <LoginButton
          className="mt-4"
          onClick={() => {
            handleSubmit();
          }}
        >
          {isLoading ? <Loader buttonLoader /> : "Login"}
        </LoginButton>
      </LoginContainer>
    </LoginWrapper>
  );
};

export default Login;
