import { Button } from 'react-bootstrap'
import styled from 'styled-components'

interface ButtonProsp {
  left?: boolean
}

const PrimaryButton = styled(Button)<ButtonProsp>`
  font-size: 14px;
  text-align: center;
  align-items: center;
  font-weight: 500;
  margin-left: ${({ left }) => (left ? '10px' : null)};
`

export default PrimaryButton
