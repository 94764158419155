export const tableHeader = [
    'Name',
    'Role',
    'Speciality',
    'Status',
    "Delete"
]

  export interface InitilaValues {
    name: string;
    role: string;
    speciality: string;
    description: string;
    profile_pic:string | null;
}

export const initailValues: InitilaValues = {
    name:'',
    role: '',
    speciality: '',
    description: '',
    profile_pic: null,
}