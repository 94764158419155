/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import { ReactElement, useState } from "react";
import { FlexWrapper, PageWrapper, SectionTitle } from "../../../component";
import Column, { ColumnCardWrapper } from "../../../component/Column";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import moment from "moment";
import { Button, Modal, Table } from "react-bootstrap";

const ReadApplication = (): ReactElement => {
  const { setSelectedApplicationForm } = useSelector(
    (state: RootState) => ({
      setSelectedApplicationForm: state.inbox.setSelectedApplicationForm,
    }),
    shallowEqual
  );

  const [modal, setModal] = useState("");
  const [img, setModalImg] = useState<any>("");

  const determineLangeage = (rateing: any) => {
    let languageDetials;
    switch (rateing) {
      case "1":
        languageDetials = "Beginner";
        break;
      case "2":
        languageDetials = "Intermediate";
        break;
      case "3":
        languageDetials = "Proficient";
        break;
      case "4":
        languageDetials = "Fluent";
        break;
      case "5":
        languageDetials = "Native";
        break;
    }
    return languageDetials;
  };

  console.log(JSON.parse(setSelectedApplicationForm?.prefer_to_work_with));

  return (
    <PageWrapper>
      <FlexWrapper
        noMargin
        backgroundColor=" #d2d4f3"
        justifyContent="space-between"
      >
        <SectionTitle title={"Detail view of Application"} hasBackButton />
      </FlexWrapper>
      <FlexWrapper justifyContent="space-around">
        <FlexWrapper justifyContent="center" noMargin>
          <h6>Personal Details</h6>
        </FlexWrapper>
        <ColumnCardWrapper style={{ marginRight: "10px" }}>
          <Column
            keyName="First name"
            value={setSelectedApplicationForm?.first_name}
          />
          <Column
            keyName="Last name"
            value={setSelectedApplicationForm?.last_name}
          />
          <Column
            keyName="Address"
            value={setSelectedApplicationForm?.address}
          />
          <Column
            keyName="Postal code"
            value={setSelectedApplicationForm?.postal_code}
          />
          <Column
            keyName="Area name"
            value={setSelectedApplicationForm?.area_name}
          />
          <Column keyName="City" value={setSelectedApplicationForm?.city} />
          <Column
            keyName="Contact number"
            value={setSelectedApplicationForm?.contact_number}
          />{" "}
          <Column keyName="Email" value={setSelectedApplicationForm?.email} />
          <Column
            keyName="Date of birth"
            value={setSelectedApplicationForm?.dob}
          />{" "}
          <Column
            keyName="I have a Swedish bank account"
            value={setSelectedApplicationForm?.i_have_a_swedish_bank_account === "Ja"?("Yes"):(setSelectedApplicationForm?.i_have_a_swedish_bank_account === "Nej"?("No"):(setSelectedApplicationForm?.i_have_a_swedish_bank_account))}
          />
          <Column
            keyName="I prefer to work with "
            value={`${
              JSON.parse(setSelectedApplicationForm?.prefer_to_work_with)[0]
            } - ${
              JSON.parse(setSelectedApplicationForm?.prefer_to_work_with)[1]
            } - ${
              JSON.parse(setSelectedApplicationForm?.prefer_to_work_with)[2]
            }`}
          />
          <Column
            keyName="My preferred work location/city"
            value={setSelectedApplicationForm?.preferred_work_with}
          />
        </ColumnCardWrapper>
        <ColumnCardWrapper>
          <Column
            keyName="Briefly describe why you would like to work for DoCare"
            value={setSelectedApplicationForm?.why_work_for_DoCare}
          />
          <Column
            keyName="Describe your interests and hobbies"
            value={setSelectedApplicationForm?.interests_and_hobbies}
          />
          <Column
            keyName="Tell us what you are currently doing"
            value={
              setSelectedApplicationForm?.tell_us_what_you_are_currently_doing ===
              "Yes"
                ? "I am a student"
                : "No"
            }
          />
          <Column
            keyName="Type of study"
            value={setSelectedApplicationForm?.type_of_study}
          />
          <Column
            keyName="State hours/week*"
            value={setSelectedApplicationForm?.per_state_hours_week}
          />
          <Column
            keyName="Institution name"
            value={setSelectedApplicationForm?.institution_name}
          />{" "}
          <Column
            keyName="Proof of your studies"
            value={""}
            children={
              setSelectedApplicationForm?.proof_of_your_studies ? (
                <Button
                  onClick={() => {
                    setModalImg(
                      setSelectedApplicationForm?.proof_of_your_studies
                    );
                  }}
                  style={{ fontSize: "14px" }}
                >
                  View
                </Button>
              ) : (
                ""
              )
            }
          />
          <Column
            keyName="I am self-employed/freelancer"
            value={setSelectedApplicationForm?.i_am_self_employed_freelancer}
          />{" "}
          <Column
            keyName="I am retired/pensioner"
            value={setSelectedApplicationForm?.i_am_retired_pensioner}
          />{" "}
          <Column
            keyName="I have another primary occupation"
            value={
              setSelectedApplicationForm?.i_have_another_primary_occupation
            }
          />{" "}

          {setSelectedApplicationForm?.i_have_another_primary_occupation=== "Yes" && setSelectedApplicationForm?.healthcare_name !== null && <Column
            keyName="Specify"
            value={
              setSelectedApplicationForm?.healthcare_name
            }
          />}{" "}

        </ColumnCardWrapper>
      </FlexWrapper>

      <FlexWrapper justifyContent="space-around">
        <FlexWrapper justifyContent="center" noMargin>
          <h6>Work Experience & Education Details</h6>
        </FlexWrapper>
        <ColumnCardWrapper style={{ marginRight: "10px" }}>
          <Column
            keyName="I have experience working with children"
            value={
              setSelectedApplicationForm?.experience_with_children === "Yes"
                ? ""
                : "No"
            }
            children={
              setSelectedApplicationForm?.experience_with_children === "Yes" ? (
                <Button
                  onClick={() => {
                    setModal("Children");
                  }}
                  style={{ fontSize: "14px" }}
                >
                  View
                </Button>
              ) : (
                ""
              )
            }
          />
          <Column
            keyName="I have experience working with elderly people"
            value={
              setSelectedApplicationForm?.experience_with_elderly === "Yes"
                ? ""
                : "No"
            }
            children={
              setSelectedApplicationForm?.experience_with_elderly === "Yes" ? (
                <Button
                  onClick={() => {
                    setModal("Elderly");
                  }}
                  style={{ fontSize: "14px" }}
                >
                  View
                </Button>
              ) : (
                ""
              )
            }
          />
          <Column
            keyName="I have studies in healthcare/childcare"
            value={
              setSelectedApplicationForm
                ?.studies_or_work_experience_in_healthcare?.length
                ? setSelectedApplicationForm?.studies_or_work_experience_in_healthcare
                : "No"
            }
          />
          <Column
            keyName="I have other work experience"
            value={
              setSelectedApplicationForm?.other_work_experience === "Yes"
                ? ""
                : "No"
            }
            children={
              setSelectedApplicationForm?.other_work_experience === "Yes" ? (
                <Button
                  onClick={() => {
                    setModal("Other");
                  }}
                  style={{ fontSize: "14px" }}
                >
                  View
                </Button>
              ) : (
                ""
              )
            }
          />{" "}
        </ColumnCardWrapper>
        <ColumnCardWrapper style={{ marginRight: "10px" }}>
          <Column
            keyName="State your education"
            value={setSelectedApplicationForm?.education_level}
          />
          <Column
            keyName="Name of the institution"
            value={setSelectedApplicationForm?.name_of_the_institution}
          />
          <Column
            keyName="From"
            value={moment(setSelectedApplicationForm?.education_from).format(
              "MMMM-YYYY"
            )}
          />
          <Column
            keyName="To"
            value={moment(setSelectedApplicationForm?.education_to).format(
              "MMMM-YYYY"
            )}
          />
        </ColumnCardWrapper>
        <FlexWrapper justifyContent="space-around">
          <FlexWrapper justifyContent="center" noMargin>
            <h6>Availability & Language Skills</h6>
          </FlexWrapper>
          <ColumnCardWrapper style={{ marginRight: "10px" }}>
            <Column
              keyName="I can start working"
              value={setSelectedApplicationForm?.can_start_working}
            />
            <Column
              keyName="Date"
              value={setSelectedApplicationForm?.working_date}
            />
            <Column
              keyName="I am available to work"
              value={setSelectedApplicationForm?.available_to_work}
            />
            <Column
              keyName="Select hours/week"
              value={setSelectedApplicationForm?.hours_per_week}
            />{" "}
            <Column
              keyName="I can commit to be available for work for at least 6 months"
              value={setSelectedApplicationForm?.work_for_at_least_6_months === "Ja"?("Yes"):(setSelectedApplicationForm?.work_for_at_least_6_months === "Nej"?("No"):(setSelectedApplicationForm?.work_for_at_least_6_months))}
              
            />
          </ColumnCardWrapper>
          <ColumnCardWrapper style={{ marginRight: "10px" }}>
            <Column
              keyName="Swedish"
              value={determineLangeage(
                `${setSelectedApplicationForm?.lang_swedish_rate}`
              )}
            />
            <Column
              keyName="English"
              value={determineLangeage(
                `${setSelectedApplicationForm?.lang_english_rate}`
              )}
            />
            {JSON.parse(
              setSelectedApplicationForm?.other_language_details
            )?.map((lan: any, index: any) => {
              return (
                <Column
                  key={index}
                  keyName={lan?.name}
                  value={determineLangeage(`${lan?.rate}`)}
                />
              );
            })}
          </ColumnCardWrapper>
        </FlexWrapper>
        <FlexWrapper justifyContent="space-around">
          <FlexWrapper justifyContent="center" noMargin>
            <h6>Other Information</h6>
          </FlexWrapper>
          <ColumnCardWrapper style={{ marginRight: "10px" }}>
            <Column
              keyName="I have a valid police clearance certificate"
              value={setSelectedApplicationForm?.police_clearance_certificate === "Ja"?("Yes"):(setSelectedApplicationForm?.police_clearance_certificate === "Nej"?("No"):(setSelectedApplicationForm?.police_clearance_certificate))}
              
            />
            <Column
              keyName="Upload a police clearance certificate"
              value={""}
              children={
                // eslint-disable-next-line jsx-a11y/alt-text
                <Button
                  disabled={
                    setSelectedApplicationForm?.police_clearance_certificate ===
                    "No"
                  }
                  onClick={() => {
                    setModalImg(
                      setSelectedApplicationForm?.police_clearance_certificate_details
                    );
                  }}
                >
                  View
                </Button>
              }
            />{" "}
            <Column
              keyName="I agree to a credit and background check"
              value={setSelectedApplicationForm?.agree_to_credit_and_background_check === "Ja"?("Yes"):(setSelectedApplicationForm?.agree_to_credit_and_background_check === "Nej"?("No"):(setSelectedApplicationForm?.agree_to_credit_and_background_check))}
              
            />{" "}
            <Column
              keyName="I have a right to work in Sweden"
              value={setSelectedApplicationForm?.right_to_work_sweden === "Ja"?("Yes"):(setSelectedApplicationForm?.right_to_work_sweden === "Nej"?("No"):(setSelectedApplicationForm?.right_to_work_sweden))}
             
            />{" "}
            <Column
              keyName="Citizen Type"
              value={setSelectedApplicationForm?.work_citizin_type}
            />
            <Column
              keyName="Upload the main page of your passport/work permit"
              value={""}
              children={
                // eslint-disable-next-line jsx-a11y/alt-text
                <Button
                  disabled={
                    setSelectedApplicationForm?.right_to_work_sweden === "No"
                  }
                  onClick={() => {
                    setModalImg(setSelectedApplicationForm?.passport);
                  }}
                >
                  View
                </Button>
              }
            />
          </ColumnCardWrapper>
          <ColumnCardWrapper>
            <Column
              keyName="I have a valid driver's licence"
              value={setSelectedApplicationForm?.valid_driver_licence === "Ja"?("Yes"):(setSelectedApplicationForm?.valid_driver_licence === "Nej"?("No"):(setSelectedApplicationForm?.valid_driver_licence))}
           
            />
            <Column
              keyName="I am allergic to animals"
              value={setSelectedApplicationForm?.allergic_to_animals === "Ja"?("Yes"):(setSelectedApplicationForm?.allergic_to_animals === "Nej"?("No"):(setSelectedApplicationForm?.allergic_to_animals))}
              
            />
            <Column
              keyName="Specify"
              value={setSelectedApplicationForm?.allergy_details}
            />
            <Column
              keyName="additional caregiving duties"
              value={setSelectedApplicationForm?.caregiving_duties === "Ja"?("Yes"):(setSelectedApplicationForm?.caregiving_duties === "Nej"?("No"):(setSelectedApplicationForm?.caregiving_duties))}
              
            />
            <Column
              keyName="How did you hear about us?"
              value={setSelectedApplicationForm?.how_do_you_hear_about_us}
            />{" "}
            <Column
              keyName="Consent Received on"
              value={moment(setSelectedApplicationForm?.created_at).format(
                "YYYY-MM-DD"
              )}
            />
          </ColumnCardWrapper>
        </FlexWrapper>
      </FlexWrapper>
      <Modal
        show={!!modal}
        size="xl"
        centered
        onHide={() => {
          setModal("");
        }}
      >
        <Modal.Header closeButton>{modal} List</Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Sno</th>
                <th>title_of_the_role_children</th>
                <th>duration_of_time_children</th>
                <th>key_responsibilities_children</th>
              </tr>
            </thead>
            <tbody>
              {modal === "Elderly" &&
                JSON.parse(
                  setSelectedApplicationForm?.elderly_people_work_exp_details
                )?.map((lan: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{lan?.title_of_the_role_elderly}</td>
                      <td>{lan?.duration_of_time_elderly}</td>
                      <td>{lan?.Key_responsibilities_elderly}</td>
                    </tr>
                  );
                })}
              {modal === "Children" &&
                JSON.parse(
                  setSelectedApplicationForm?.children_work_exp_details
                )?.map((lan: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{lan?.title_of_the_role_children}</td>
                      <td>{lan?.duration_of_time_children}</td>
                      <td>{lan?.key_responsibilities_children}</td>
                    </tr>
                  );
                })}
              {modal === "Other" &&
                JSON.parse(
                  setSelectedApplicationForm?.other_work_exp_details
                )?.map((lan: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{lan?.title_of_the_role_other}</td>
                      <td>{lan?.duration_of_time_other}</td>
                      <td>{lan?.Key_responsibilities_other}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal
        show={!!img}
        size="lg"
        centered
        onHide={() => {
          setModalImg("");
        }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe
            src={`https://api.dohr.io/${img}#toolbar=0`}
            width="100%"
            height="500px"
            allowFullScreen={true}
            loading="lazy"
            onContextMenu={() => false}
            style={{ position: "relative" }}
          ></iframe>
        </Modal.Body>
      </Modal>
    </PageWrapper>
  );
};

export default ReadApplication;
