import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faBars,
  faXmark,
  faChevronDown,
  faChevronUp,
  faTimesCircle,
  faGear,
  faChevronLeft,
  faDownload,
  faEye,
  faInfo,
  faChevronRight,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

import {
  faEdit,
  faBell,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faBars,
  faXmark,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faTimesCircle,
  faBell,
  faGear,
  faEye,
  faTrashAlt,
  faChevronLeft,
  faEdit,
  faInfo,
  faDownload,
  faRightFromBracket
);
