/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateIsMenuOpen } from "../../state/menu/action";
import { RootState } from "../../state/store";
import FlexWrapper from "../FlexWrapper";
import user from "../../asserts/user.png";
import {
  Hamburg,
  HamburgeButton,
  HeaderWrapper,
  Logo,
  LogoWrapper,
  SideContainer,
  DropdownContent,
  DropdownContentLink,
  ProfileContainer,
  ProfileFlexWrapper,
  ProfileImage,
  UserName,
} from "./subcomponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateIsloggedIn } from "../../state/controllers/actions";

const Header = (): ReactElement => {
  const { isMenuOpen } = useSelector((state: RootState) => ({
    isMenuOpen: state.menu.isMenuOpen,
  }));
  const dispatch = useDispatch();
  const handleToggleMenu = () => {
    dispatch(updateIsMenuOpen(!isMenuOpen));
  };
  return (
    <HeaderWrapper>
      <SideContainer>
        <LogoWrapper>
          <Logo>DOCARE</Logo>
        </LogoWrapper>
        <HamburgeButton variant="transparent" onClick={handleToggleMenu}>
          <Hamburg>
            <span className={"line"} />
            <span className={"line"} />
            <span className={"line"} />
          </Hamburg>
        </HamburgeButton>
      </SideContainer>
      <FlexWrapper
        justifyContent="flex-end"
        style={{ width: "70%", marginRight: "4%" }}
      >
        {/* <form>
          <Select name="languages">
            <option value="English">English</option>
            <option value="Swedish">Swedish</option>
          </Select>
        </form>
        <IconWrapper>
          <FontAwesomeIcon
            icon={["far", "bell"]}
            style={{ color: "white", fontSize: "20px" }}
          />
        </IconWrapper>
        <IconWrapper>
          <FontAwesomeIcon
            icon={["fas", "gear"]}
            style={{ color: "white", fontSize: "20px" }}
          />
        </IconWrapper> */}
        {/* <ProfileContainer>
          <ProfileFlexWrapper>
            <ProfileImage src={user} />
            <UserName>Admin</UserName>
          </ProfileFlexWrapper>
          <DropdownContent>
            <DropdownContentLink href="#">Logout</DropdownContentLink>
          </DropdownContent>
        </ProfileContainer> */}
        <span
          className="logout"
          onClick={() => {
            dispatch(updateIsloggedIn(false));
          }}
        >
          Logout{" "}
          <FontAwesomeIcon
            className="ico"
            icon={["fas", "right-from-bracket"]}
            style={{ color: "white", fontSize: "20px" }}
          />
        </span>
      </FlexWrapper>
    </HeaderWrapper>
  );
};

export default Header;
