export const tableHeader = [
  "Sno",
  "Name",
  "Application date", 
  "Action",
];

export const applyList = [
  {
    name: "Swathi",
    date: "08/12/2023",
  },
  {
    name: "Bindu",
    date: "14/12/2023",
  },
];
