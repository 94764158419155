import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import {
  AddNewTestimonialProps,
  GetContactResposne,
  GetEmailResposne,
  GetTestimonialResponse,
  GetPricePlaneResponse,
  AddPricePlaneProps,
  AddTeamMemberProps,
  GetWorkWith,
} from "./types";
import ROUTES from "../../const/routes";
import axios from "axios";

export const getContactDetails = createAsyncThunk(
  "contact/getContact",
  async (): Promise<Array<GetContactResposne>> => {
    const response = await api.get(`${"get_contact_us"}`);
    return response.data.message;
  }
);

export const getEmailsDetails = createAsyncThunk(
  "emails/getEmails",
  async (): Promise<Array<GetEmailResposne>> => {
    const response = await api.get("get_stay_connected");
    return response.data.message;
  }
);

export const getTestimonialLists = createAsyncThunk(
  "testimonial/getTestimonials",
  async (): Promise<Array<GetTestimonialResponse>> => {
    const resposne = await api.get("get_testimonials");
    return resposne.data.message;
  }
);

export const addNewTestimonial = createAsyncThunk(
  "testimonial/addTestimonials",
  async (requestPayload: AddNewTestimonialProps): Promise<any> => {
    const resposne = await api.post("add_testimonials", requestPayload);
    if (resposne?.data.success) {
      window.location.href = ROUTES.TESTIMONIAL_LIST;
    }
    return resposne.data.message;
  }
);
export const getTeamLists = createAsyncThunk(
  "teamlist/getTeamlist",
  async (): Promise<Array<GetTestimonialResponse>> => {
    const resposne = await api.get("get_team_member");
    return resposne.data.message;
  }
);
export const addteammember = createAsyncThunk(
  "team detail/addTeamMember",
  async (requestPayload: AddTeamMemberProps): Promise<any> => {
    const resposne = await api.post("add_team_member", requestPayload);
    if (resposne?.data.success) {
      window.location.href = ROUTES.TEAM_LIST;
    }
    return resposne.data.message;
  }
);

export const getPricePlane = createAsyncThunk(
  "pricePlane/getPricePlane",
  async (): Promise<Array<GetPricePlaneResponse>> => {
    const resposne = await api.get("get_price_plans");
    return resposne.data.message;
  }
);

export const addNewPricePlane = createAsyncThunk(
  "testimonial/addTestimonials",
  async (requestPayload: AddPricePlaneProps): Promise<any> => {
    const resposne = await api.post("add_price_plans", requestPayload);
    if (resposne?.data.success) {
      window.location.href = ROUTES.PRICE_PLANE_LIST;
    }
    return resposne.data.message;
  }
);

export const commonDeleteApi = createAsyncThunk(
  "delete/deleteApi",
  async (requestPayload: { endPoint: string; id: string }): Promise<any> => {
    const resposne = await api.post(requestPayload.endPoint, requestPayload);

    return resposne;
  }
);

export const editPricePlane = createAsyncThunk(
  "price/editPrice",
  async (rP: any): Promise<any> => {
    const repsone = await api.post("edit_price_plans", rP);
    return repsone;
  }
);

export const getWorkWith = createAsyncThunk(
  "ww/getWW",
  async (): Promise<Array<GetWorkWith>> => {
    const resposne = await api.get("get_work_with_us");
    return resposne.data.message;
  }
);

export const adminLogin = createAsyncThunk(
  "login/adminLogin",
  async (userDetials: { email_id: string; password: string }): Promise<any> => {
    const resposne = axios.post("https://api.dohr.io/AdminLogin", {
      email_id: userDetials?.email_id,
      password: userDetials?.password,
    });
    return resposne.then((res) => res?.data?.message?.data);
  }
);
