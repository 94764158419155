import React, { ReactElement, useEffect, useState } from "react";
import {
  DropdownWrapper,
  FlexWrapper,
  Icon,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../../../component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../../../state/store";
import {
  commonDeleteApi,
  getContactDetails,
} from "../../../../../state/controllers/api";
import { Modal, Table } from "react-bootstrap";
import { tableHeader } from "./const";
import DataExportButton from "../../../../../component/ExcelExport/export";
import ROUTES from "../../../../../const/routes";
import { useNavigate } from "react-router";
import { updateSelectedMessage } from "../../../../../state/controllers/actions";
import moment from "moment";
import Buttons from "../../../../../component/Modal/subcomponent";

const Messages = (): ReactElement => {
  const {
    inbox: { getContactusLists, isLoading },
  } = useSelector(
    (state: RootState) => ({
      inbox: state.inbox,
    }),
    shallowEqual
  );

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setsearchTerm] = useState<any>("");
  const [id, setId] = useState("");

  useEffect(() => {
    dispatch(getContactDetails());
  }, [dispatch]);

  return (
    <PageWrapper>
      <FlexWrapper
        noMargin
        backgroundColor=" #d2d4f3"
        justifyContent="space-between"
      >
        <SectionTitle title="Queries from website" />
      </FlexWrapper>{" "}
      <FlexWrapper justifyContent="end" noMargin>
        <DropdownWrapper margin="0 10px">
          <Input
            value={searchTerm}
            onChange={(e: any) => {
              setsearchTerm(e);
            }}
            placeholder="Search"
          />
        </DropdownWrapper>{" "}
        <div>
          <DataExportButton
            exportData={getContactusLists}
            fileName="Queries from website"
          />
        </div>
      </FlexWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <Table striped borderless hover>
          <thead style={{ backgroundColor: "#003824", color: "white" }}>
            <tr style={{ textAlign: "center" }}>
              {tableHeader?.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getContactusLists
              // eslint-disable-next-line array-callback-return
              ?.filter((userFilter) => {
                if (searchTerm === "") {
                  return userFilter;
                } else if (
                  userFilter?.first_name?.toLowerCase().includes(searchTerm) ||
                  userFilter?.last_name?.toLowerCase().includes(searchTerm)
                ) {
                  return userFilter;
                }
              })
              ?.map((data, index) => {
                return (
                  <tr style={{ textAlign: "center" }} key={index}>
                    <td>{`${data?.first_name}${data?.last_name}`}</td>
                    <td>{`${data?.enquiry} `}</td>
                    <td>{`${moment(data?.created_at).format(
                      "DD-MM-YYYY"
                    )} `}</td>
                    <td>
                      <Icon
                        icon={["fas", "eye"]}
                        size="sm"
                        onClick={() => {
                          dispatch(updateSelectedMessage(data));
                          navigate(ROUTES.VIEW_MESSAGE);
                        }}
                      />
                      <Icon
                        icon={["far", "trash-alt"]}
                        size="sm"
                        onClick={() => {
                          setId(data?.id);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
      <Modal
        show={!!id}
        onHide={() => {
          setId("");
        }}
      >
        <Modal.Header>
          <b>Are you sure you want to delete?</b>
        </Modal.Header>
        <Modal.Footer>
          <FlexWrapper justifyContent="end" noMargin noPadding>
            <Buttons
              backGround="green"
              onClick={() => {
                dispatch(
                  commonDeleteApi({
                    endPoint: "/delete_contact_us",
                    id: id,
                  })
                ).then(() => {
                  setId("");
                  dispatch(getContactDetails());
                });
              }}
            >
              {isLoading ? <Loader buttonLoader text={"please wait"} /> : "Yes"}
            </Buttons>
            <Buttons
              backGround="red"
              onClick={() => {
                setId("");
              }}
            >
              No
            </Buttons>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};

export default Messages;
