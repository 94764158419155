import { ReactElement, useState } from "react";
import { Menu } from "./types";
import menuItems from "../../const/menu";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import {
  ListItems,
  MenuContainer,
  MenuListWrapper,
  FontIcon,
  MenuLogo,
  Anchor,
  ChevronIcon,
} from "./subcomponents";
import { updateIsMenuOpen } from "../../state/menu/action";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useLocation } from "react-router-dom";

interface SideMenuProps {
  menus?: Array<Menu>;
}

const SideMenu = ({ menus = menuItems.user }: SideMenuProps): ReactElement => {
  const { pathname } = useLocation();

  const { isMenuOpen } = useSelector(
    (state: RootState) => ({
      isMenuOpen: state.menu.isMenuOpen,
    }),
    shallowEqual
  );
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [childMenuOpen, setchildMenuOpen] = useState(false);

  const dispatch = useDispatch();
  return (
    <MenuContainer>
      {menus.map((menu, index) => {
        const { childs = [] } = menu;
        const isSelected = menu.childs
          ? menu.childs.some((item) => pathname.includes(item.to))
          : pathname.includes(menu.to);
        return (
          <>
            <ListItems
              key={`menu-${index}`}
              $isSelected={isSelected}
              onClick={() => {
                if (index === selectedMenu) {
                  setchildMenuOpen(!childMenuOpen);
                } else {
                  setchildMenuOpen(true);
                }
                setSelectedMenu(selectedMenu >= 0 ? index : -1);
                if (!isMenuOpen) {
                  dispatch(updateIsMenuOpen(!isMenuOpen));
                }
              }}
            >
              <MenuListWrapper>
                {typeof menu?.icon === "string" ? (
                  <MenuLogo src={menu?.icon} alt="menu" />
                ) : (
                  <FontIcon
                    icon={menu?.icon}
                    size="sm"
                    $isSelected={isSelected}
                    isMenuOpen={isMenuOpen}
                  />
                )}
                {menu?.childs && menu.childs.length && isMenuOpen ? (
                  <>
                    <Anchor $isSelected={isSelected} to={""}>
                      {menu?.label}
                    </Anchor>
                    <ChevronIcon
                      icon={[
                        "fas",
                        selectedMenu === index && childMenuOpen
                          ? "chevron-down"
                          : "chevron-right",
                      ]}
                      size="sm"
                    />
                  </>
                ) : (
                  <Anchor to={menu?.to} $isSelected={isSelected}>
                    {isMenuOpen && menu?.label}
                  </Anchor>
                )}
              </MenuListWrapper>
            </ListItems>
            {selectedMenu === index && isMenuOpen && childMenuOpen && (
              <MenuContainer isChild key={`subMenu-${index}`}>
                {childs.map((child, index) => (
                  <ListItems
                    $isChild
                    key={`child-${index}`}
                    $isSelected={pathname.includes(child?.to)}
                  >
                    <FontIcon
                      style={{ fontSize: "12px" }}
                      $isSelected={pathname.includes(child?.to)}
                      icon={child?.icon as IconProp}
                      size="sm"
                    />
                    <Anchor
                      $isSelected={pathname.includes(child?.to)}
                      font
                      to={child?.to}
                    >
                      {child?.label}
                    </Anchor>
                  </ListItems>
                ))}
              </MenuContainer>
            )}
          </>
        );
      })}
    </MenuContainer>
  );
};

export default SideMenu;
