import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import fonts, { weight } from "../../const/fonts";

export const NavBar = styled(Nav)`
  margin-top: 4%;
  @media (max-width: 600px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`;

export const NavBarContainer = styled.ul`
  display: flex;
  list-style: none;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MenuItemList = styled.li`
  position: relative;
  font-size: 14px;
  width: auto;
  a {
    display: block;
    color: white;
    width: auto;
    text-decoration: none;
    text-align: left;
    padding: 6%;
    &:hover,
    &:active,
    &:focus {
      color: white;
      background-color: #003824;
    }
    @media (max-width: 600px) {
      background-color: #003824;
    }
  }
  @media (max-width: 600px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`;

export const MenuItemButton = styled.button`
  color: white;
  display: flex;
  font-size: ${fonts.tiny}px;
  font-weight: ${weight.bold};
  border: none;
  background-color: #003824;
  cursor: pointer;
  margin-right: 12%;
  padding: 0.7rem 0.51rem;
  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: #003824;
    transition: all 3s;
  }
  .line {
    margin: auto;
  }
  @media (max-width: 600px) {
    padding: 0.7rem 0rem;
  }
`;

export const Anchor = styled(Link)``;

export const ChevronIcon = styled(FontAwesomeIcon)`
  padding: 0;
  margin: auto;
  margin-left: 4px;
`;
