import styled from "styled-components";

interface DropdownWrapperProps {
  width?: string;
  margin?: string;
}

const DropdownWrapper = styled.div<DropdownWrapperProps>` 
  width: ${({ width }) => width || "22%"};
  margin: ${({ margin }) => (margin ? margin : "1%")};
  #editable-dropdown {
    height: 100%;
  }
`;

export default DropdownWrapper;
