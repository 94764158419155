const ROUTES = {
  LOGIN: "/",
  MESSAGES: "/messages",
  VIEW_MESSAGE: "/viewMessage",
  WORK_WITH_US: "/workWithus",
  SUBSCRIPTION: "/subscription",
  TESTIMONIAL_LIST: "/testimonialList",
  ADD_TESTIMONIAL: "/addTestimonial",
  PRICE_PLANE_LIST: '/pricePlaneList',
  ADD_PRICEPLANE: '/addPricePlane',
  EDIT_PRICEPLANE: '/editPricePlane',
  READ_APPLICATION: '/readApplication',
  TEAM_LIST: '/teamlist',
  ADD_TEAM_MEMBER: '/addteammember'
};

export default ROUTES;
