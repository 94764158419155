import { inboxSlice } from "./reducers";

export { inboxSlice } from "./reducers";

const {
  updateSelectedMessage,
  updateSelectedPricePlane,
  updateSelectedApplicationForm,
  updateIsloggedIn,
} = inboxSlice.actions;

export {
  updateSelectedMessage,
  updateSelectedPricePlane,
  updateSelectedApplicationForm,
  updateIsloggedIn,
};
