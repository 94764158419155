import React, { ReactElement, useEffect, useState } from "react";
import {
  CardWrapper,
  DropdownWrapper,
  FlexWrapper,
  Icon,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../component";
import { Modal, Table } from "react-bootstrap";
import { tableHeader } from "./const";
import Buttons from "../../../component/Modal/subcomponent";
import { useNavigate } from "react-router";
import ROUTES from "../../../const/routes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { commonDeleteApi, getWorkWith } from "../../../state/controllers/api";
import moment from "moment";
import DataExportButton from "../../../component/ExcelExport/export";
import { updateSelectedApplicationForm } from "../../../state/controllers/actions";

const Workwithus = (): ReactElement => {
  const { getWorkWithusResposne, isLoading } = useSelector(
    (state: RootState) => ({
      getWorkWithusResposne: state.inbox.getWorkWithusResposne,
      isLoading: state.inbox.isLoading,
    }),
    shallowEqual
  );
  const [modal, setModal] = useState<any>("");
  // const [modal, setModal] = useState({
  //   approval: false,
  //   approvalList: false,
  //   approvalDelete: false,
  //   denied: false,
  //   deniedList: false,
  //   deniedDelete: false,
  //   name: "",
  //   appliedListDelete: false,
  // });
  const navigation = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [searchTerm, setsearchTerm] = useState<any>("");

  useEffect(() => {
    dispatch(getWorkWith());
  }, [dispatch]);

  return (
    <PageWrapper>
      <FlexWrapper
        noMargin
        backgroundColor=" #d2d4f3"
        justifyContent="space-between"
      >
        {" "}
        <SectionTitle title="Applications List" />
        {/* {modal?.approvalList ? (
            <SectionTitle title="Approval Applications List" hasBackButton />
          ) : modal?.deniedList ? (
            <SectionTitle title="Denied Applications List" hasBackButton />
          ) : (
            <SectionTitle title="Incoming Applications List" hasBackButton />
          )} */}
        {/* <Input value={modal.name} placeholder="Select Name" width="40%" /> */}
      </FlexWrapper>{" "}
      <CardWrapper>
        {/* <FlexWrapper noMargin>
          <DropdownWrapper>
            <EditableDropdown
              placeholder="Select Filter"
              dropdownList={[
                {
                  id: "01",
                  name: "Applied",
                },
                {
                  id: "02",
                  name: "Approved",
                },
                {
                  id: "03",
                  name: "Denied",
                },
              ]}
            />
          </DropdownWrapper>
        </FlexWrapper> */}
        {/* <FlexWrapper justifyContent="center">
          <Table striped borderless hover>
            <thead style={{ backgroundColor: "#003824", color: "white" }}>
              <tr style={{ textAlign: "center" }}>
                {tableHeader.map((eachHeading) => (
                  <th>{eachHeading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!modal.approvalList ? (
                !modal.deniedList ? (
                  applyList?.map((eachApplicant: any, index: any) => {
                    return (
                      <tr style={{ textAlign: "center" }} key={index}>
                        <td>{index + 1}</td>
                        <td>{eachApplicant.name}</td>
                        <td>{eachApplicant.date}</td>
                        <td>
                          {!approvalDeniedButton ? (
                            <Button
                              onClick={() => {
                                setApprovalDeniedButton(true);
                              }}
                            >
                              Applied
                            </Button>
                          ) : (
                            <div>
                              <Button
                                onClick={() => {
                                  setModal({
                                    ...modal,
                                    approval: true,
                                  });
                                }}
                              >
                                Approval
                              </Button>{" "}
                              <Button
                                onClick={() => {
                                  setModal({
                                    ...modal,
                                    denied: true,
                                  });
                                }}
                              >
                                Denied
                              </Button>
                            </div>
                          )}
                        </td>
                        <td>
                          <Icon
                            icon={["far", "trash-alt"]}
                            size="sm"
                            onClick={() => {
                              setModal({
                                ...modal,
                                appliedListDelete: true,
                              });
                            }}
                          ></Icon>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr style={{ textAlign: "center" }}>
                    <td>1</td>
                    <td>Bindu</td>
                    <td>14/12/2023</td>
                    <td>Denied</td>
                    <td>
                      <Icon
                        icon={["fas", "eye"]}
                        size="sm"
                        onClick={() => {}}
                      ></Icon>
                      <Icon
                        icon={["far", "trash-alt"]}
                        size="sm"
                        onClick={() => {
                          setModal({
                            ...modal,
                            deniedDelete: true,
                          });
                        }}
                      ></Icon>
                    </td>
                  </tr>
                )
              ) : (
                <tr style={{ textAlign: "center" }}>
                  <td>1</td>
                  <td>Swathi</td>
                  <td>08/12/2023</td>
                  <td>Approved</td>
                  <td>
                    <Icon
                      icon={["fas", "eye"]}
                      size="sm"
                      onClick={() => {
                        navigation(ROUTES.READ_APPLICATION, {
                          state: { name: "Swathi" },
                        });
                      }}
                    ></Icon>
                    <Icon
                      icon={["far", "trash-alt"]}
                      size="sm"
                      onClick={() => {
                        setModal({
                          ...modal,
                          appliedListDelete: true,
                        });
                      }}
                    ></Icon>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </FlexWrapper>{" "} */}
        <FlexWrapper justifyContent="end" noMargin>
          <DropdownWrapper margin="0 10px">
            <Input
              value={searchTerm}
              onChange={(e: any) => {
                setsearchTerm(e);
              }}
              placeholder="Search"
            />
          </DropdownWrapper>{" "}
          <div>
            <DataExportButton
              exportData={getWorkWithusResposne}
              fileName="Applications List"
            />
          </div>
        </FlexWrapper>
        <FlexWrapper justifyContent="center">
          <Table striped borderless hover>
            <thead style={{ backgroundColor: "#003824", color: "white" }}>
              <tr style={{ textAlign: "center" }}>
                {tableHeader?.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {getWorkWithusResposne?.length &&
                getWorkWithusResposne
                  ?.filter((userFilter) => {
                    if (searchTerm === "") {
                      return userFilter;
                    } else if (
                      userFilter?.first_name
                        ?.toLowerCase()
                        .includes(searchTerm) ||
                      userFilter?.last_name?.toLowerCase().includes(searchTerm)
                    ) {
                      return userFilter;
                    }
                  })
                  ?.map((data, index) => {
                    return (
                      <tr style={{ textAlign: "center" }} key={index}>
                        <td>{`${index + 1} `}</td>
                        <td>{`${data?.first_name}${" "}${data?.last_name}`}</td>
                        <td>
                          {moment(data?.created_at).format("DD/MM/YYYY")}
                        </td>{" "}
                        {/* <td>Approved</td> */}
                        {/* <td>
                      <Button
                        onClick={() => {
                          setApprovalDeniedButton(true);
                        }}
                      >
                        Approve
                      </Button>{" "}
                      <Button
                        onClick={() => {
                          setApprovalDeniedButton(true);
                        }}
                      >
                        Deny
                      </Button>
                    </td> */}
                        <td>
                          <Icon
                            icon={["fas", "eye"]}
                            size="sm"
                            onClick={() => {
                              dispatch(updateSelectedApplicationForm(data));
                              navigation(ROUTES.READ_APPLICATION, {});
                            }}
                          ></Icon>
                          <Icon
                            icon={["far", "trash-alt"]}
                            size="sm"
                            onClick={() => {
                              setModal(data?.id);
                            }}
                          ></Icon>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </FlexWrapper>
      </CardWrapper>
      <Modal
        show={!!modal}
        onHide={() => {
          setModal("");
        }}
      >
        <Modal.Header>
          <b>Are you sure you want to delete this applicant?</b>
        </Modal.Header>
        <Modal.Footer>
          <FlexWrapper justifyContent="end" noMargin noPadding>
            <Buttons
              backGround="green"
              onClick={() => {
                dispatch(
                  commonDeleteApi({
                    endPoint: "delete_work_with_us",
                    id: modal,
                  })
                ).then(() => {
                  dispatch(getWorkWith());
                  setModal("");
                });
              }}
            >
              {isLoading ? <Loader buttonLoader text={"please wait"} /> : "Yes"}
            </Buttons>
            <Buttons
              backGround="red"
              onClick={() => {
                setModal("");
              }}
            >
              No
            </Buttons>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};

export default Workwithus;
