import { Route, Routes } from "react-router-dom";
import ROUTES from "./const/routes";
import { 
  AddTestimonial,
  Messages,
  PricePlaneList,
  SubscriptionList, 
  Testimonial, 
  ViewMessage,
  Workwithus, 
  ReadApplication ,
  Teamlist,
  Addteammember 
} from "./pages";
import AddPricePlane from "./pages/admin/publish/priceplane/add";

const PageRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.MESSAGES} element={<Messages />} />
      <Route path={ROUTES.WORK_WITH_US} element={<Workwithus />} />
      <Route path={ROUTES.VIEW_MESSAGE} element={<ViewMessage />} />
      <Route path={ROUTES.SUBSCRIPTION} element={<SubscriptionList />} />
      <Route path={ROUTES.TESTIMONIAL_LIST} element={<Testimonial />} />
      <Route path={ROUTES.ADD_TESTIMONIAL} element={<AddTestimonial />} />
      <Route path={ROUTES.PRICE_PLANE_LIST} element={<PricePlaneList />} />
      <Route path={ROUTES.ADD_PRICEPLANE} element={<AddPricePlane />} />
      <Route path={ROUTES.READ_APPLICATION} element={<ReadApplication />} />
      <Route path={ROUTES.TEAM_LIST} element={<Teamlist />} />
      <Route path={ROUTES.ADD_TEAM_MEMBER} element={<Addteammember />} />
      <Route path={ROUTES.EDIT_PRICEPLANE} element={<AddPricePlane />} />
    </Routes>
  );
};

export default PageRoutes;
 