import { AddNewTestimonialProps } from "../../../../state/controllers/types"

export const tableHeader = [
    "Relevant page",
    'Name',
    'Location',
    'Status',
    'Delete'
] 
export const initailValues: AddNewTestimonialProps = {
    name: "",
    role: "",
    description: "",
    profile_pic: "",
    location: ""
}