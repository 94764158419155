import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import SideMenu from "../../component/Sidemenu";
import { RootState } from "../../state/store";
import { MenuWrapperProps, SideNavigationProps } from "../Sidemenu/types";

const MenuWrapper = styled.div<MenuWrapperProps>`
  display: flex;
  @media (max-width: 769px) {
    width: ${({ isMenuOpen }) => (isMenuOpen ? "25%" : "0")};
  }
  @media (max-width: 415px) {
    width: ${({ isMenuOpen }) => (isMenuOpen ? "12rem" : "0")};
    position: absolute;
    height: 100%;
  }
  width: ${({ isMenuOpen }) => (isMenuOpen ? "14rem" : 0)};
  transition: left 0.3s ease, width 0.45s ease;
`;

const SideNavigation = ({ menus }: SideNavigationProps): ReactElement => {
  const { isMenuOpen } = useSelector((state: RootState) => ({
    isMenuOpen: state.menu.isMenuOpen,
  }));


  return (
    <MenuWrapper isMenuOpen={isMenuOpen}>
      <SideMenu menus={menus} />
    </MenuWrapper>
  );
};

export default SideNavigation;
