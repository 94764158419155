import { ReactElement } from "react";
import FlexWrapper from "../FlexWrapper";
import { H4, Small } from "../../typography";
import ColumnProps from "./typings";
import styled from "styled-components";
import { Card } from "react-bootstrap";

const Column = ({
  keyName,
  value,
  input,
  isCheckbox,children
}: ColumnProps): ReactElement => {
  return (
    <FlexWrapper
      justifyContent="space-between"
      noMargin
      noPadding
      style={{ padding: "10px 4px" }}
    >
      <H4>{`${keyName}: `}</H4>
      <Small hasPadding color={"blue"}>
        {value && value}
        {isCheckbox && <input type="checkbox" checked={input} />}
      </Small>
      {children}
    </FlexWrapper>
  );
};

export default Column;

export const ColumnCardWrapper = styled(Card)`
  width: 45%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 5px;
  @media (max-width: 415px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;
