import { ReactElement, useState } from "react";
import {
  PageWrapper,
  CardWrapper,
  FlexWrapper,
  SectionTitle,
  DropdownWrapper,
  Input,
  PrimaryButton,
  EditableDropdown,
} from "../../../../component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addNewPricePlane,
  editPricePlane,
} from "../../../../state/controllers/api";
import { AppDispatch, RootState } from "../../../../state/store";
import { AddPricePlaneProps } from "../../../../state/controllers/types";
import { DropdownListProps } from "../../../../component/EditableDropdown/typings";
import { PriceType } from "./const";
import { useLocation, useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import Buttons from "../../../../component/Modal/subcomponent";
import ROUTES from "../../../../const/routes";

const AddPricePlane = (): ReactElement => {
  const {
    inbox: { addPricePlaneProsp },
  } = useSelector(
    (state: RootState) => ({
      inbox: state.inbox,
    }),
    shallowEqual
  );
  const [values, setValues] = useState(
    addPricePlaneProsp as AddPricePlaneProps
  );
  const [modal, setModal] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isEdit = location.pathname === ROUTES.EDIT_PRICEPLANE;

  const paleType = PriceType?.filter((d) => d?.name === values?.plan_type);

  return (
    <PageWrapper>
      <CardWrapper>
        <FlexWrapper
          noMargin
          backgroundColor=" #d2d4f3"
          justifyContent="space-between"
        >
          <SectionTitle
            title={isEdit ? "Edit Publish Prices" : '"Publish Prices"'}
            hasBackButton
          />
        </FlexWrapper>{" "}
        <FlexWrapper noMargin>
          <DropdownWrapper>
            <Input
              value={location?.state?.name || values?.name}
              label="Page Name"
              placeholder="Page Name"
              isDisabled
            />{" "}
          </DropdownWrapper>

          <DropdownWrapper>
            <EditableDropdown
              dropdownList={PriceType}
              placeholder="Select one"
              title="Plan Type"
              defaultValue={{
                id: paleType[0]?.id,
                name: paleType[0]?.name,
              }}
              handleSelect={(item: DropdownListProps) => {
                setValues({
                  ...values,
                  plan_type: item?.name,
                });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={values?.plan_price}
              label="Plan Price"
              placeholder="Specify price/hour"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  plan_price: value,
                });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={values?.visits}
              label="Comment 1"
              placeholder="Write here"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  visits: value,
                });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={values?.time_period}
              label="Comment 2"
              placeholder="Write here"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  time_period: value,
                });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={values?.scheduling}
              label="Comment 3"
              placeholder="Write here"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  scheduling: value,
                });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={values?.comment4}
              label="Comment 4"
              placeholder="Write here"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  comment4: value,
                });
              }}
            />
          </DropdownWrapper>
        </FlexWrapper>
        <FlexWrapper noPadding justifyContent="center">
          <PrimaryButton
            onClick={() => {
              setModal(true);
            }}
          >
            Publish
          </PrimaryButton>
        </FlexWrapper>
      </CardWrapper>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
      >
        <Modal.Header>
          <b>Do you want to publish?</b>
        </Modal.Header>
        <Modal.Footer>
          <FlexWrapper justifyContent="end" noMargin noPadding>
            <Buttons
              backGround="green"
              onClick={() => {
                isEdit
                  ? dispatch(editPricePlane({ ...values })).then(() => {
                      setModal(false);
                      navigate(ROUTES.PRICE_PLANE_LIST);
                    })
                  : dispatch(
                      addNewPricePlane({
                        ...values,
                        name: location?.state.name,
                      })
                    ).then(() => {
                      setModal(false);
                      navigate(ROUTES.PRICE_PLANE_LIST);
                    });
              }}
            >
              Yes
            </Buttons>
            <Buttons
              backGround="red"
              onClick={() => {
                setModal(false);
              }}
            >
              No
            </Buttons>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};

export default AddPricePlane;
