export const tableHeader = [
  "Relevant page",
  "Plan Type",
  "Plan Price",
  "Created at",
  "Actions",
];

export const PriceType = [
  {
    id: "01",
    name: "VIP",
  },
  {
    id: "02",
    name: "Basic",
  },
  {
    id: "03",
    name: "Pay as you go",
  },
];

export const PagesDropdown = [
  {
    id: "02",
    name: "Seniors",
  },
  {
    id: "03",
    name: "Families",
  },
];

export const PagesTestimonailDropdown = [
  {
    id: "01",
    name: "Services",
  },
  {
    id: "02",
    name: "Companies",
  }
];
