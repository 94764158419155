import styled from "styled-components"

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 415px) {
    width: 98%;
  }
`
