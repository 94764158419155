import React, { useEffect, useState } from "react";
import {
  FlexWrapper,
  PageWrapper,
  SectionTitle,
  PrimaryButton,
  CardWrapper,
  Icon,
  DropdownWrapper,
  EditableDropdown,
  Loader,
} from "../../../../component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  commonDeleteApi,
  getTestimonialLists,
} from "../../../../state/controllers/api";
import { tableHeader } from "./const";
import { Modal, Table } from "react-bootstrap";
import { AppDispatch, RootState } from "../../../../state/store";
import { useNavigate } from "react-router";
import ROUTES from "../../../../const/routes";
import { DropdownListProps } from "../../../../component/EditableDropdown/typings";
import { PagesTestimonailDropdown } from "../priceplane/const";
import Buttons from "../../../../component/Modal/subcomponent";

const Testimonial = () => {
  const {
    inbox: { getTestimonialListResposne, isLoading },
  } = useSelector(
    (state: RootState) => ({
      inbox: state.inbox,
    }),
    shallowEqual
  );
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [pageValues, setPageValues] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    if (!!pageValues) {
      navigate(ROUTES.ADD_TESTIMONIAL, {
        state: { name: pageValues },
      });
    }
  }, [navigate, pageValues]);

  useEffect(() => {
    dispatch(getTestimonialLists());
  }, [dispatch]);

  return (
    <PageWrapper>
      <CardWrapper>
        <FlexWrapper
          noMargin
          backgroundColor=" #d2d4f3"
          justifyContent="space-between"
        >
          <div>
            <SectionTitle title="Testimonials List" />
          </div>
          <PrimaryButton
            onClick={() => {
              setModal(true);
            }}
          >
            Create new Testimonial
          </PrimaryButton>
        </FlexWrapper>{" "}
        <FlexWrapper justifyContent="end" noMargin>
          {isLoading ? (
            <Loader />
          ) : (
            <Table striped borderless hover>
              <thead style={{ backgroundColor: "#003824", color: "white" }}>
                <tr style={{ textAlign: "center" }}>
                  {tableHeader?.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getTestimonialListResposne?.map((data, index) => {
                  return (
                    <tr style={{ textAlign: "center" }} key={index}>
                      <td>{`${data?.role} `}</td>
                      <td>{`${data?.name} `}</td>
                      <td>{`${data?.location} `}</td>
                      <td
                        style={{
                          color: data?.is_active === 1 ? "green" : "red",
                        }}
                      >
                        {data?.is_active === 1 ? "ACTIVE" : "INACTIVE"}
                      </td>
                      <td>
                        <Icon
                          icon={["far", "trash-alt"]}
                          onClick={() => {
                            setId(data?.id);
                          }}
                        />{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </FlexWrapper>
      </CardWrapper>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
      >
        <Modal.Header>
          Specify the page where the testimonials need to be published?
        </Modal.Header>
        <Modal.Body>
          <DropdownWrapper width="100%">
            <EditableDropdown
              dropdownList={PagesTestimonailDropdown}
              placeholder="Select relevant page"
              handleSelect={(item: DropdownListProps) => {
                setPageValues(item?.name);
              }}
            />
          </DropdownWrapper>
        </Modal.Body>
      </Modal>
      <Modal
        show={!!id}
        onHide={() => {
          setId("");
        }}
      >
        <Modal.Header>
          <b>Are you sure you want to delete?</b>
        </Modal.Header>
        <Modal.Footer>
          <FlexWrapper justifyContent="end" noMargin noPadding>
            <Buttons
              backGround="green"
              onClick={() => {
                dispatch(
                  commonDeleteApi({
                    endPoint: "/delete_testimonials",
                    id: id,
                  })
                ).then(() => {
                  setId("");
                  dispatch(getTestimonialLists());
                });
              }}
            >
              {isLoading ? <Loader buttonLoader text={"please wait"} /> : "Yes"}
            </Buttons>
            <Buttons
              backGround="red"
              onClick={() => {
                setId("");
              }}
            >
              No
            </Buttons>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};

export default Testimonial;
