import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getContactDetails,
  getEmailsDetails,
  getPricePlane,
  getTestimonialLists,
  getTeamLists,
  getWorkWith,
  commonDeleteApi,
  adminLogin,
} from "./api";
import {
  GetContactResposne,
  GetEmailResposne,
  GetPricePlaneResponse,
  GetTestimonialResponse,
  initialState,
  GetTeamMemberResponse,
  GetWorkWith,
} from "./types";

export const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    updateSelectedMessage: (
      state,
      action: PayloadAction<GetContactResposne>
    ) => {
      state.setSelectedMessage = action.payload;
    },
    updateSelectedPricePlane: (state, action: PayloadAction<any>) => {
      state.addPricePlaneProsp = action?.payload;
    },
    updateSelectedApplicationForm: (
      state,
      action: PayloadAction<GetWorkWith>
    ) => {
      state.setSelectedApplicationForm = action?.payload;
    },
    updateIsloggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action?.payload;
    },
  },
  extraReducers: {
    [getContactDetails.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getContactDetails.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetContactResposne>>
    ) => {
      state.isLoading = false;
      state.getContactusLists = action.payload;
    },
    [getContactDetails.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getEmailsDetails.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getEmailsDetails.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetEmailResposne>>
    ) => {
      state.isLoading = false;
      state.getEmailsList = action.payload;
    },
    [getEmailsDetails.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getTestimonialLists.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getTestimonialLists.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetTestimonialResponse>>
    ) => {
      state.isLoading = false;
      state.getTestimonialListResposne = action.payload;
    },
    [getTestimonialLists.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getPricePlane.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getPricePlane.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetPricePlaneResponse>>
    ) => {
      state.isLoading = false;
      state.getPricePlaneList = action.payload;
    },
    [getPricePlane.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getPricePlane.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getTeamLists.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetTeamMemberResponse>>
    ) => {
      state.isLoading = false;
      state.addteamlist = action.payload;
    },
    [getTeamLists.rejected.toString()]: (state) => {
      state.isLoading = false;
    },

    [getWorkWith.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getWorkWith.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetWorkWith>>
    ) => {
      state.isLoading = false;
      state.getWorkWithusResposne = action.payload;
    },
    [getWorkWith.rejected.toString()]: (state) => {
      state.isLoading = false;
    },

    [commonDeleteApi.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [commonDeleteApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetWorkWith>>
    ) => {
      state.isLoading = false;
      state.getWorkWithusResposne = action.payload;
    },
    [commonDeleteApi.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [adminLogin.pending.toString()]: (state) => {
      state.isLoading = true;
      state.isLoggedIn = false;
    },
    [adminLogin.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.isLoggedIn = !!action?.payload;
      console.log(action?.payload, "q");
    },
    [adminLogin.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.isLoggedIn = false;
    },
  },
});

export default inboxSlice.reducer;
