import styled from "styled-components";
import { weight } from "../../const/fonts";
import { InputWrapperProps } from "./typings";

export const InputWrapper = styled.div<InputWrapperProps>`
  width: ${({ width }) => width || "100%"};
  display: flex;
  flex-direction: column;
  height: ${({ height }) => (height ? height : "50px")};
  margin-bottom: ${({ hasPadding }) => (hasPadding ? "12px" : "0")};

  @media (max-width: 415px) {
    width: 100%;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
pointer-events: none;
opacity: 0.7;
`}

  .form-control.focus {
    border-color: ${({ theme: { input } }) => input.focus};
    box-shadow: none;
  }

  .is-valid {
    border-color: ${({ theme: { input } }) => input.focus};
    box-shadow: none;
  }
`;

export const InputLabel = styled.label`
  font-weight: ${weight.bold};
`;
