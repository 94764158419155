import ROUTES from "./routes";

const users = [
  {
    icon: ["fas", "lock"],
    label: "Inbox",
    childs: [
      {
        icon: ['fas', 'lock'],
        label: 'Messages',
        to: ROUTES.MESSAGES
      },
      {
        icon: ['fas', 'lock'],
        label: 'Subscriptions ',
        to: ROUTES.SUBSCRIPTION
      }
    ]
  },
  {
    icon: ["fas", "lock"],
    label: "Applications",
    to: ROUTES.WORK_WITH_US,
  },
  {
    icon: ["fas", "lock"],
    label: "Publish",
    childs: [
      {
        icon: ['fas', 'lock'],
        label: 'Testimonials',
        to: ROUTES.TESTIMONIAL_LIST
      },
      {
        icon: ['fas', 'lock'],
        label: 'Team Details',
        to: ROUTES.TEAM_LIST
      },
      {
        icon: ['fas', 'lock'],
        label: 'Price plan',
        to: ROUTES.PRICE_PLANE_LIST
      }
    ]
  },

];

const menus = {
  user: users,
};

export default menus;
