import styled from "styled-components";

interface TitleProps {
  hasBorder?: boolean;
}

const Title = styled.div<TitleProps>`
display: flex;
  padding: ${({ hasBorder }) => (hasBorder ? "12px" : "0")};
  border-bottom: ${({ hasBorder, theme }) =>
    hasBorder ? `1px solid ${theme.border}` : "0"};
  width: 100%;
`;

export default Title;
