import React, { ReactElement, useEffect, useState } from "react";
import {
  FlexWrapper,
  PageWrapper,
  SectionTitle,
  PrimaryButton,
  CardWrapper,
  DropdownWrapper,
  EditableDropdown,
  Icon,
  Loader,
} from "../../../../component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  commonDeleteApi,
  getPricePlane,
} from "../../../../state/controllers/api";
import { PagesDropdown, tableHeader } from "./const";
import { Modal, Table } from "react-bootstrap";
import { AppDispatch, RootState } from "../../../../state/store";
import { useNavigate } from "react-router";
import ROUTES from "../../../../const/routes";
import moment from "moment";
import { DropdownListProps } from "../../../../component/EditableDropdown/typings";
import { updateSelectedPricePlane } from "../../../../state/controllers/actions";
import Buttons from "../../../../component/Modal/subcomponent";

const PricePlaneList = (): ReactElement => {
  const {
    inbox: { getPricePlaneList, isLoading },
  } = useSelector(
    (state: RootState) => ({
      inbox: state.inbox,
    }),
    shallowEqual
  );
  const [modal, setModal] = useState(false);
  const [pageValues, setPageValues] = useState("");
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState("");

  useEffect(() => {
    if (!!pageValues) {
      navigate(ROUTES.ADD_PRICEPLANE, {
        state: { name: pageValues },
      });
    }
  }, [navigate, pageValues]);

  useEffect(() => {
    dispatch(getPricePlane());
  }, [dispatch]);

  return (
    <PageWrapper>
      <CardWrapper>
        <FlexWrapper
          noMargin
          backgroundColor=" #d2d4f3"
          justifyContent="space-between"
        >
          <div>
            <SectionTitle title="Price Plan List" />
          </div>
          <PrimaryButton
            onClick={() => {
              setModal(true);
            }}
          >
            Create Price Plan
          </PrimaryButton>
        </FlexWrapper>{" "}
        <FlexWrapper justifyContent="end" noMargin>
          {isLoading ? (
            <Loader />
          ) : (
            <Table striped borderless hover>
              <thead style={{ backgroundColor: "#003824", color: "white" }}>
                <tr style={{ textAlign: "center" }}>
                  {tableHeader?.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getPricePlaneList?.map((data, index) => {
                  return (
                    <tr style={{ textAlign: "center" }} key={index}>
                      <td>{`${data?.name} `}</td>
                      <td>{`${data?.plan_type} `}</td>
                      <td>{`${data?.plan_price} `}</td>
                      <td>
                        {moment(data?.created_at).format("DD-MM-YYYY hh-mm-ss")}
                      </td>
                      <td>
                        <Icon
                          icon={["far", "edit"]}
                          size="sm"
                          onClick={() => {
                            dispatch(updateSelectedPricePlane(data));
                            navigate(ROUTES.EDIT_PRICEPLANE);
                          }}
                        ></Icon>{" "}
                        <Icon
                          icon={["far", "trash-alt"]}
                          size="sm"
                          onClick={() => {
                            setId(data?.id);
                          }}
                        ></Icon>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </FlexWrapper>
      </CardWrapper>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
      >
        <Modal.Header>
          Specify the page name where the price plan needs to be modified
        </Modal.Header>
        <Modal.Body>
          <DropdownWrapper width="100%">
            <EditableDropdown
              dropdownList={PagesDropdown}
              placeholder="Select relevant page"
              handleSelect={(item: DropdownListProps) => {
                setPageValues(item?.name);
              }}
            />
          </DropdownWrapper>
        </Modal.Body>
      </Modal>
      <Modal
        show={!!id}
        onHide={() => {
          setId("");
        }}
      >
        <Modal.Header>
          <b>Are you sure you want to delete?</b>
        </Modal.Header>
        <Modal.Footer>
          <FlexWrapper justifyContent="end" noMargin noPadding>
            <Buttons
              backGround="green"
              onClick={() => {
                dispatch(
                  commonDeleteApi({
                    endPoint: "/delete_price_plans",
                    id: id,
                  })
                ).then(() => {
                  dispatch(getPricePlane());
                  setId("");
                });
              }}
            >
              {isLoading ? <Loader buttonLoader text={"please wait"} /> : "Yes"}
            </Buttons>
            <Buttons
              backGround="red"
              onClick={() => {
                setId("");
              }}
            >
              No
            </Buttons>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};

export default PricePlaneList;
