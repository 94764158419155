import { ReactElement, useEffect, useState } from "react";
import {
  PageWrapper,
  FlexWrapper,
  SectionTitle,
  Icon,
  Loader,
} from "../../../../component";
import { Modal, Table } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  commonDeleteApi,
  getEmailsDetails,
} from "../../../../state/controllers/api";
import { AppDispatch, RootState } from "../../../../state/store";
import Buttons from "../../../../component/Modal/subcomponent";

const SubscriptionList = (): ReactElement => {
  const {
    inbox: { getEmailsList,isLoading },
  } = useSelector(
    (state: RootState) => ({
      inbox: state.inbox,
    }),
    shallowEqual
  );

  const [id, setId] = useState("");

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmailsDetails());
  }, [dispatch]);

  return (
    <PageWrapper>
      <FlexWrapper
        noMargin
        backgroundColor=" #d2d4f3"
        justifyContent="space-between"
      >
        <SectionTitle title="Subscribers list" />
      </FlexWrapper>{" "}
      {isLoading ? (
        <Loader />
      ) : (
        <Table striped borderless hover>
          <thead style={{ backgroundColor: "#003824", color: "white" }}>
            <tr style={{ textAlign: "center" }}>
              <th>Emails</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {getEmailsList?.map((data, index) => {
              return (
                <tr style={{ textAlign: "center" }} key={index}>
                  <td>{data?.email_id}</td>
                  <td>
                    <Icon
                      icon={["far", "trash-alt"]}
                      size="sm"
                      onClick={() => {
                        setId(data?.id);
                      }}
                    ></Icon>
                  </td>
                </tr>
              );
            })}
            <tr></tr>
          </tbody>
        </Table>
      )}
      <Modal
        show={!!id}
        onHide={() => {
          setId("");
        }}
      >
        <Modal.Header>
          <b>Are you sure you want to delete?</b>
        </Modal.Header>
        <Modal.Footer>
          <FlexWrapper justifyContent="end" noMargin noPadding>
            <Buttons
              backGround="green"
              onClick={() => {
                dispatch(
                  commonDeleteApi({
                    endPoint: "/delete_stay_connected",
                    id: id,
                  })
                ).then(() => {
                  setId("");
                  dispatch(getEmailsDetails());
                });
              }}
            >
              {isLoading ? <Loader buttonLoader text={"please wait"} /> : "Yes"}
            </Buttons>
            <Buttons
              backGround="red"
              onClick={() => {
                setId("");
              }}
            >
              No
            </Buttons>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};

export default SubscriptionList;
