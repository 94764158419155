import styled, { ThemeProvider } from "styled-components";
import "./App.css";
import Header from "./component/Header";
import theme from "./const/theme";
import PageRoutes from "./routes";
import SideNavigation from "./component/SideNavigation";
import { weight } from "./const/fonts";
import Login from "./Login";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "./state/store";

const Body = styled.div`
  display: flex;
  body {
    overflow: auto;
    scroll-behavior: smooth;
  }
  #container {
    z-index: 0;
  }
  .react-datepicker-popper {
    z-index: 999;
    padding: 0 !important;
  }
  th {
    font-weight: ${weight.bold};
  }
  td {
    font-weight: ${weight.normal};
  }
`;

const App = () => {
  const { isLoggedIn } = useSelector(
    (state: RootState) => ({
      isLoggedIn: state.inbox.isLoggedIn,
    }),
    shallowEqual
  );
  console.log(isLoggedIn, "isLoggedIn");
  return (
    <ThemeProvider theme={theme}>
      {isLoggedIn ? (
        <>
          <Header />
          <Body>
            <SideNavigation />
            <PageRoutes />
          </Body>
        </>
      ) : (
        <Login />
      )}
    </ThemeProvider>
  );
};

export default App;
