export const colors = {
    black: '#111111',
    lightGrey: '#d8dbde',
    gray: '#434344',
    white: '#fff',
    active: '#f9f9f9',
    jordyBlue: '#9caaff',
    indigo: '#1d2596',
    green: '#198754',
    whitesmoke: '#F5F5F5',
    lavenderBlush: '#f2f0f1',
    purple: '#49384a',
    lightGreen: '#b7ceaf',
    amber: '#ffc107',
    red: '#e90a32',
    cyanBlue: '#9caaff',
    limeGreen: '#36a817',
    navyBlue: '#3732d0',
    stateBlue: '#7B5DC6',
    safetyYellow: '#EAD10A',
    darkWine: '#351038',
    anchor: '#06001ec4',
    heavyGray: '#2d2d2e',
    orange: '#efc4a7',
    heavyOrange: '#f27d30',
    lightblue: '#242e6f',
    menublue: '#242e6f',
    title: '#141A45'
}

const sidemenu = {
    container: colors.white,
    border: '#d6d4d4',
    anchor: {
        hover: colors.black
    },
    menu: {
        background: colors.active
    },
    icon: {
        selected: colors.purple,
        normal: colors.gray
    }
}

const theme = {
    sideMenu: sidemenu,
    border: '#d6d4d4',
    input: {
        border: '#ced4da',
        background: colors.white,
        color: '#6c757d',
        active: '#46a9d8',
        focus: colors.purple
    },
    dropDown: {
        background: colors.active,
        color: colors.black,
        error: `#dc3545`,
        success: colors.purple
    },
}

export default theme
