import { ChangeEvent, ReactElement, useState } from "react";
import {
  PageWrapper,
  CardWrapper,
  FlexWrapper,
  SectionTitle,
  DropdownWrapper,
  Input,
  PrimaryButton,
} from "../../../../component";
import { initailValues } from "./const";
import { FormControl, Modal } from "react-bootstrap";
import { Label } from "../../../../typography";
import { useDispatch } from "react-redux";
import { addNewTestimonial } from "../../../../state/controllers/api";
import Buttons from "../../../../component/Modal/subcomponent";
import { AddNewTestimonialProps } from "../../../../state/controllers/types";
import { AppDispatch } from "../../../../state/store";
import { useLocation } from "react-router";

const AddTestimonial = (): ReactElement => {
  const [values, setValues] = useState(initailValues as AddNewTestimonialProps);
  const [modal, setModal] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const loaction = useLocation();
  const role = loaction.state.name;
  console.log(values);
  return (
    <PageWrapper>
      <CardWrapper>
        <FlexWrapper
          noMargin
          backgroundColor=" #d2d4f3"
          justifyContent="space-between"
        >
          <SectionTitle title="Create New Testimonial" hasBackButton />
        </FlexWrapper>{" "}
        <FlexWrapper noMargin>
          <DropdownWrapper>
            <Input
              value={values?.name}
              label="First Name"
              isRequired
              placeholder="First Name"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  name: value,
                });
              }}
            />{" "}
          </DropdownWrapper>{" "}
          <DropdownWrapper>
            <Label>Profile image</Label>
            <FormControl
              type="file"
              onChange={(event: any) => {
                const files = event.target.files?.[0];
                let reader = new FileReader();
                reader.readAsDataURL(files as unknown as Blob);
                reader.onload = () => {
                  setValues({
                    ...values,
                    profile_pic: reader.result,
                  });
                };
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              value={values?.location}
              label="Location"
              placeholder="Location"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  location: value,
                });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper width="100%" className="mt-4">
            <Label>Customers feedback/review</Label>
            <FormControl
              value={values?.description}
              as="textarea"
              placeholder="Customers feedback/review"
              rows={3}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setValues({
                  ...values,
                  description: e.target.value,
                });
              }}
            />
          </DropdownWrapper>{" "}
        </FlexWrapper>
        <FlexWrapper noPadding justifyContent="center">
          <PrimaryButton
            onClick={() => {
              setModal(true);
            }}
          >
            Publish
          </PrimaryButton>
        </FlexWrapper>
      </CardWrapper>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
      >
        <Modal.Header>
          <b>Do you want to publish?</b>
        </Modal.Header>
        <Modal.Footer>
          <FlexWrapper justifyContent="end" noMargin noPadding>
            <Buttons
              backGround="green"
              onClick={() => {
                dispatch(addNewTestimonial({ ...values, role: role })).then(
                  () => {
                    setModal(false);
                  }
                );
              }}
            >
              Yes
            </Buttons>
            <Buttons
              backGround="red"
              onClick={() => {
                setModal(false);
              }}
            >
              No
            </Buttons>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};

export default AddTestimonial;
