import React from "react";
import { PrimaryButton } from "..";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ExcelExport {
  exportData: any;
  fileName: string;
}

const DataExportButton = ({ exportData, fileName }: ExcelExport) => {
  const handleExport = async () => {
    const formattedData = exportData.map((item: any) => {
      return {
        ...item,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, "Messages.xlsx");
  };

  return (
    <PrimaryButton
      variant="success"
      onClick={handleExport}
      disabled={exportData.length === 0}
    >
      <FontAwesomeIcon icon={["fas", "download"]} /> Export
    </PrimaryButton>
  );
};

export default DataExportButton;
