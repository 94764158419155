import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const Icon = styled(FontAwesomeIcon)`
  border-color: #ffffff;
  color: #363636;
  text-transform: capitalize;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px 15px 0px 0px;
  &:hover,
  &:focus {
    border-color: black;
    background: none;
    color: #003824;
  }
  max-width: 40px;
`
