import React, { ChangeEvent, useState } from "react";
import {
  FlexWrapper,
  PageWrapper,
  SectionTitle,
  CardWrapper,
  DropdownWrapper,
  Input,
  PrimaryButton,
} from "../../../../component";
import { Label } from "../../../../typography";
import { FormControl } from "react-bootstrap";
import { InitilaValues, initailValues } from "./const";
import { useDispatch } from "react-redux";
import { addteammember } from "../../../../state/controllers/api";

const Addteammember = () => {
  const [values, setValues] = useState(initailValues as InitilaValues);
  const dispatch = useDispatch();
  const handleProfilePicChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          const updatedValues = {
            ...values,
            profile_pic: reader.result,
          };
          setValues(updatedValues);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <PageWrapper>
      <CardWrapper>
        <FlexWrapper
          noMargin
          backgroundColor=" #d2d4f3"
          justifyContent="space-between"
        >
          <div>
            <SectionTitle title="Add Team Member" hasBackButton />
          </div>
        </FlexWrapper>{" "}
        <FlexWrapper noMargin>
          <DropdownWrapper>
            <Input
              value={values?.name}
              label="Name"
              placeholder="Name"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  name: value,
                });
              }}
            />{" "}
          </DropdownWrapper>{" "}
          <DropdownWrapper>
            <Input
              value={values.role}
              label="Role"
              placeholder="Role"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  role: value,
                });
              }}
            />{" "}
          </DropdownWrapper>{" "}
          <DropdownWrapper>
            <Input
              value={values?.speciality}
              label="Speciality"
              placeholder="Speciality"
              onChange={(value: string) => {
                setValues({
                  ...values,
                  speciality: value,
                });
              }}
            />{" "}
          </DropdownWrapper>{" "}
          <DropdownWrapper >
            <Label>Profile image</Label>
            <FormControl type="file" onChange={handleProfilePicChange} />
          </DropdownWrapper>{" "}
          <DropdownWrapper width="100%" className="mt-2">
            <Label>Description</Label>
            <FormControl
              value={values?.description}
              as="textarea"
              placeholder="Description"
              rows={3}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setValues({
                  ...values,
                  description: e.target.value,
                });
              }}
            />
          </DropdownWrapper>
        </FlexWrapper>
        <FlexWrapper noPadding justifyContent="center">
          <PrimaryButton
            onClick={() => {
              
              dispatch(dispatch(addteammember({...values })));
            }}
          >
            Add
          </PrimaryButton>
        </FlexWrapper>
      </CardWrapper>
    </PageWrapper>
  );
};

export default Addteammember;
