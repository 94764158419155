import React, { useEffect, useState } from "react";
import {
  FlexWrapper,
  PageWrapper,
  SectionTitle,
  CardWrapper,
  PrimaryButton,
  Icon,
  Loader,
} from "../../../../component";
import { Modal, Table } from "react-bootstrap";
import { tableHeader } from "./const";
import ROUTES from "../../../../const/routes";
import { useNavigate } from "react-router";
import {
  commonDeleteApi,
  getTeamLists,
} from "../../../../state/controllers/api";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../state/store";
import Buttons from "../../../../component/Modal/subcomponent";

const Teamlist = () => {
  const {
    inbox: { addteamlist, isLoading },
  } = useSelector(
    (state: RootState) => ({
      inbox: state.inbox,
    }),
    shallowEqual
  );

  const [id, setId] = useState<any>("");

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getTeamLists());
  }, [dispatch]);

  return (
    <PageWrapper>
      <CardWrapper>
        <FlexWrapper
          noMargin
          backgroundColor=" #d2d4f3"
          justifyContent="space-between"
        >
          <div>
            <SectionTitle title="Team List" />
          </div>
          <PrimaryButton
            onClick={() => {
              navigate(ROUTES.ADD_TEAM_MEMBER);
            }}
          >
            Add team member
          </PrimaryButton>
        </FlexWrapper>{" "}
        <FlexWrapper justifyContent="end" noMargin>
          <Table striped borderless hover>
            <thead style={{ backgroundColor: "#003824", color: "white" }}>
              <tr style={{ textAlign: "center" }}>
                {tableHeader?.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            {addteamlist?.map((data, index) => {
              return (
                <tr style={{ textAlign: "center" }} key={index}>
                  <td>{`${data?.name} `}</td>
                  <td>{`${data?.role} `}</td>
                  <td>{`${data?.speciality} `}</td>
                  <td
                    style={{ color: data?.is_active === 1 ? "green" : "red" }}
                  >
                    {data?.is_active === 1 ? "ACTIVE" : "INACTIVE"}
                  </td>
                  <td>
                    <Icon
                      icon={["far", "trash-alt"]}
                      size="sm"
                      onClick={() => {
                        setId(data?.id);
                      }}
                    ></Icon>
                  </td>
                </tr>
              );
            })}
            <tbody></tbody>
          </Table>
        </FlexWrapper>
      </CardWrapper>{" "}
      <Modal
        show={!!id}
        onHide={() => {
          setId("");
        }}
      >
        <Modal.Header>
          <b>Are you sure you want to delete?</b>
        </Modal.Header>
        <Modal.Footer>
          <FlexWrapper justifyContent="end" noMargin noPadding>
            <Buttons
              backGround="green"
              onClick={() => {
                dispatch(
                  commonDeleteApi({
                    endPoint: "/delete_team_member",
                    id: id,
                  })
                ).then(() => {
                  dispatch(getTeamLists());
                  setId("");
                });
              }}
            >
              {isLoading ? <Loader buttonLoader text={"please wait"} /> : "Yes"}
            </Buttons>
            <Buttons
              backGround="red"
              onClick={() => {
                setId("");
              }}
            >
              No
            </Buttons>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};

export default Teamlist;
