import React, { ReactElement } from "react";
import {
  CardWrapper,
  Column,
  FlexWrapper,
  PageWrapper,
  SectionTitle,
} from "../../../../../component";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../../state/store";
import moment from "moment";

const ViewMessage = (): ReactElement => {
  const {
    inbox: { setSelectedMessage },
  } = useSelector(
    (state: RootState) => ({
      inbox: state.inbox,
    }),
    shallowEqual
  );

  return (
    <PageWrapper>
      <FlexWrapper
        noMargin
        backgroundColor=" #d2d4f3"
        justifyContent="space-between"
      >
        <SectionTitle title="View Message" hasBackButton />
      </FlexWrapper>
      <FlexWrapper justifyContent="space-between">
        <CardWrapper width="45">
          <Column value={setSelectedMessage?.first_name} keyName="First name" />
          <Column value={setSelectedMessage?.last_name} keyName="Last name" />
          {setSelectedMessage?.email_id && (
            <Column value={setSelectedMessage?.email_id} keyName="Email" />
          )}{" "}
          {setSelectedMessage?.phone && (
            <Column value={setSelectedMessage?.phone} keyName="Phone Number" />
          )}
          <Column value={setSelectedMessage?.city_name} keyName="City name" />
        </CardWrapper>{" "}
        <CardWrapper width="45">
          <Column value={setSelectedMessage?.enquiry} keyName="Enquiry" />{" "}
          <Column value={setSelectedMessage?.describe_in_detail} keyName="Description" />{" "}
          <Column
            value={moment(setSelectedMessage?.created_at).format(
              "DD-MM-YYYY hh:mm:ss"
            )}
            keyName="Created at"
          />
          <Column
            input={setSelectedMessage?.privacy_policy === 1 ? true : false}
            keyName="Privacy Policy"
            isCheckbox
          />
          <Column
            input={setSelectedMessage?.docare_news_updates === 1 ? true : false}
            isCheckbox
            keyName="News Update"
          />
        </CardWrapper>
      </FlexWrapper>
    </PageWrapper>
  );
};

export default ViewMessage;
